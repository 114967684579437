$(document).on('turbolinks:load', function() {
    $(function() {
        setTimeout("$('.alert_block').fadeOut('slow')", 3000);
    });

    $(function () {
        $("#q_input_date_gteq, #q_input_date_lteq_end_of_day").on('change',calculateDateDifference);
    });
    function calculateDateDifference() {
        var startDate = $("#q_input_date_gteq").val();
        var endDate = $("#q_input_date_lteq_end_of_day").val();

        if (startDate && endDate) {
            var diffInMilliseconds = new Date(endDate) - new Date(startDate);
            var diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
            
            // 月単位の差に変換
            var diffInMonths = diffInDays / 31;

            if (diffInMonths > 1) {
                alert("エラー：1ヶ月以上の期間は許可されていません。");
                $("#q_input_date_gteq, #q_input_date_lteq_end_of_day").val("");
            } 
        }
    }
    window.gtranslateSettings = {
      "default_language": "ja",
      "languages": ["ja", "zh-CN", "zh-TW", "th", "vi", "ko", "tl", "pt", "en", "hi"],
      "wrapper_selector": ".gtranslate_wrapper",
      "switcher_horizontal_position": "right",
      "alt_flags": {"pt": "brazil"}
    };

    var script = $("<script>", {
      src: "https://cdn.gtranslate.net/widgets/latest/float.js",
      defer: true
    });

    $("head").append(script);

    $('.gt_float_switcher').on('click', 'a', function(event) {
    // Turbolinksを無効にする
    Turbolinks.visit($(this).attr('href'), { action: 'replace' });

    // クリックによる通常の遷移を無効にする
    event.preventDefault();
  });
  $('#nav-icon').on('click', function(){
		$(this).toggleClass('open');
    $('.collapse').toggleClass('is-show');
	});
  
  $('#q_input_date_during_month_1i').after('<span class="me-3">年</span>');
  $('#q_input_date_during_month_2i').after('<span>月</span>');
});
