$(document).on('turbolinks:load', function() {
    $('#general_hygiene_genre_ngs')
        .on('cocoon:before-insert', function(e, general_hygiene_genre_ng_to_be_added) {
            general_hygiene_genre_ng_to_be_added.fadeIn('slow');
        })
        .on('cocoon:after-insert', function(e, added_general_hygiene_genre_ng) {
            // e.g. set the background of inserted task
        })
        .on('cocoon:before-remove', function(e, general_hygiene_genre_ng) {
            // allow some time for the animation to complete
            $(this).data('remove-timeout', 1000);
            general_hygiene_genre_ng.fadeOut('slow');
        });
});