$(document).on('turbolinks:load', function() {
    $('#ccp_taxonomy_menus')
        .on('cocoon:before-insert', function(e, ccp_taxonomy_menu_to_be_added) {
            ccp_taxonomy_menu_to_be_added.fadeIn('slow');
        })
        .on('cocoon:after-insert', function(e, added_ccp_taxonomy_menu) {
            // e.g. set the background of inserted task
        })
        .on('cocoon:before-remove', function(e, ccp_taxonomy_menu) {
            // allow some time for the animation to complete
            $(this).data('remove-timeout', 1000);
            ccp_taxonomy_menu.fadeOut('slow');
        });
    $('#ccp_taxonomy_ng_reasons')
        .on('cocoon:before-insert', function(e, ccp_taxonomy_ng_to_be_added) {
            ccp_taxonomy_ng_to_be_added.fadeIn('slow');
        })
        .on('cocoon:after-insert', function(e, added_ccp_taxonomy_ng) {
            // e.g. set the background of inserted task
        })
        .on('cocoon:before-remove', function(e, ccp_taxonomy_ng) {
            // allow some time for the animation to complete
            $(this).data('remove-timeout', 1000);
            ccp_taxonomy_ng.fadeOut('slow');
        });
});